:root {
  --mode: 'light';
  --main-bg-color: #f2f2f2;
  --color-primary: #003399;
  --color-secondary: #006DFF;
  --color-grayish-navy-blue: #18243a;
  --color-navy-blue: #101c3d;
  --color-light-green: #68c671;

  /* // White shouldn't be any other place than the DeWork logo */
  --color-white: #fff;
  /* // */

  --color-black5: #101010;
  --color-black10: #101010;
  --color-black15: #18243A;
  --color-black20: #15244A;
  --color-black25: #101010;
  --color-black30: #808080;
  --color-black31: #E1E1E1;
  --color-black50: #828282;
  --color-black60: #4f4f4f;
  --color-black75: #e6e6e6;
  --color-black80: #fff;
  --color-black86: #f6f6f6;
  --color-black90: #b9b9b9;
  --color-black95: #f2f2f2;
  --color-black98: #c0c0c0;
  --color-black100: #f2f2f2;
  --color-backgroundBlue: #e5eaf6;

  --color-aux1: var(--color-black80);
  --color-aux2: var(--color-primary);
  --color-aux3: var(--color-black100);
  --color-aux4: var(--color-primary);
  --color-aux5: none;
  --color-aux6: var(--color-black98);
  --color-aux7: var(--color-primary);
  --color-aux8: var(--color-black100);
  --color-aux9: var(--color-black100);

  --color-aux10: var(--color-primary);
  --color-aux11: var(--color-black50);
  --color-aux12: var(--color-primary);
  --color-aux13: var(--color-primary);
  --color-aux14: transparent;
  --color-aux15: var(--color-black90);
  --color-aux16: var(--color-black100);
  --color-aux17: var(--color-primary);
  --color-aux18: var(--color-black86);
  --color-aux19: var(--color-primary);

  --color-aux20: var(--color-black86);
  --color-aux21: var(--color-black50);
  --color-aux22: var(--color-backgroundBlue);
  --color-aux23: var(--color-black86);
  --color-aux24: var(--color-black75);
  --color-aux25: var(--color-black60);

  --color-aux30: var(--color-backgroundBlue);
  --color-aux31: var(--color-black75);
  --color-aux32: var(--color-primary);
  --color-aux33: var(--color-black90);
  --color-aux34: var(--color-black75);
  --color-aux35: var(--color-backgroundBlue);
  --color-aux36: var(--color-black60);
  --color-aux37: var(--color-black90);
  --color-aux38: var(--color-primary);
  --color-aux39: var(--color-black100);
  --color-aux40: var(--color-black90);

  --color-aux51: var(--color-black100);
  --color-aux52: var(--color-black98);
  --color-aux53: var(--color-black50);
  --color-aux54: var(--color-black25);
  --color-aux55: var(--color-black90);
  --color-aux56: var(--color-black100);
  --color-aux57: var(--color-black5);
  --color-aux58: var(--color-black60);
  --color-aux59: var(--color-black90);
  --color-aux60: var(--color-primary);
  --color-aux61: var(--color-white);
  --color-aux62: var(--color-black95);
  --color-aux63: var(--color-backgroundBlue);
  --color-aux64: var(--color-black90);
  --color-aux65: var(--color-black95);
  --color-aux66: var(--color-black60);
  --color-aux67: var(--color-black60);
  --color-aux68: var(--color-primary);
  --color-aux69: var(--color-secondary);
  --color-aux70: var(--color-grayish-navy-blue);

  --color-aux71: var(--color-black60);
  --color-aux72: var(--color-white);
  --color-aux73: var(--color-primary);
  --color-aux74: var(--color-light-green);
  --color-aux75: none;
  --color-aux76: var(--gradient-primary);

  --color-aux77: var(--color-primary);
  --color-aux78: var(--color-secondary);

  --color-aux100: var(--color-black60);
  --color-aux101: var(--color-primary);
  --color-aux102: var(--color-black95);
  --color-aux103: var(--color-black80);
  --color-aux104: var(--color-black80);
  --color-aux105: var(--color-black90);
  --color-aux106: var(--color-black50);
  --color-aux107: var(--color-primary);
  --color-aux110: var(--color-black75);
  --color-aux111: var(--color-black50);
  --color-aux112: var(--color-black75);
  --color-aux113: var(--color-black60);
  --color-aux114: var(--color-black30);
  --color-aux115: var(--color-black50);
  --color-aux116: var(--color-black90);

  --color-aux150: var(--color-black15);
  --color-aux151: var(--color-black80);
  --color-aux152: var(--color-black50);
  --color-aux153: var(--color-black31);
  --color-aux154: var(--color-black15);
  --color-aux160: var(--color-black20);
  --color-aux161: var(--color-black31);
  --color-aux170: var(--color-black15);
  --color-aux171: #828282;

  /* Landing Colors  */
  --color-landingblack5: #913030;
  --color-landingblack10: #e6e6e6;
  --color-landingblack25: #c0c0c0;
  --color-landingblack50: #808080;
  --color-landingblack80: #181818;
  --color-landingblack88: #101010;
  --color-landingsecondary: #28f9da;
  --color-landingblack100: #000;
  --color-landingblack75: #404040;

  --color-green: #0eda95;
  --color-red: #ff1616;
  --color-yellow: #f2dd22;

  --color-backdrop: rgba(0, 0, 0, 0.7);
  --color-spinner: #006DFF;
  --color-spinnerBorder: var(--color-black90);
  --color-divisor: var(--color-black90);
  --color-spinner-text: var(--color-grayish-navy-blue);

  --gradient-primary: linear-gradient(
    45deg,
    var(--color-primary),
    var(--color-secondary)
  );
  --gradient-primary-animated: linear-gradient(
    45deg,
    --color-primary,
    --color-secondary,
    --color-primary,
    --color-secondary
  );
  --gradient-secondary1: linear-gradient(
    to bottom left,
    --color-secondary,
    --color-primary
  );
  --gradient-secondary2: linear-gradient(
    to top,
    --color-secondary,
    --color-primary
  );
  --gradient-roomcards: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.54) 100%
  );

  /* ///// DO NOT USE COLORS BELOW */

  --background-grey1: --color-black5;
  --background-grey2: --color-black75;

  --color-orange1: #f5cbcb;
  --color-orange: #f2994a;
  --color-young-blue: #33ccff;

  --box-shadow1: 0 0 50px rgb(#464646, 0.1);
  --box-shadow2: 0 0 30px 2px rgba(0, 0, 0, 0.3);
  --box-shadow3: 0 0 2px 0 rgba(0, 0, 0, 0.1);
  --box-shadow4: -10px 20px 50px rgba(#464646, 0.4);
  --box-shadow6: 0 20px 50px rgba(#464646, 0.4);
  --box-shadow5: 0 20px 50px rgba(--color-black75, 0.1);
  --box-shadow7: 0 1px 10px 0 rgba(0, 0, 0, 0.3);
  --box-shadow8: -30px 30px 50px rgba(#464646, 0.2);
  --box-shadow9: -10px 20px 50px rgba(#464646, 0.1);
  --box-shadow10: 0 15px 30px rgba(51, 204, 255, 0.5);
  --box-shadow11: 5px 0 15px rgba(--color-primary, 0.15);
  --box-shadow12: 0 0 0 2.60188px rgba(51, 204, 255, 0.2);
  --box-shadow13: 0px -5px 26px rgba(0, 51, 153, 0.15);

  --border-radius1: 2px;
  --border-radius2: 4px;
  --border-radius3: 8px;
  --border-radius4: 12px;
  --border-radius5: 16px;
  --border-radius6: 18px;
  --border-radius7: 20px;
  --border-radius8: 6px;
  /* //replace */

  --font-size-xl: 1.5rem;
  --font-size-l: 1rem;
  --font-size-m: 0.78rem;
  --font-size-s: 0.7rem;
  --font-size-xs: 0.6rem;

  --font-size-mobile-xl: 1rem;
  --font-size-mobile-l: 0.85rem;
  --font-size-mobile-m: 0.50rem;
  --font-size-mobile-s: 0.25rem;
  --font-size-mobile-xs: 0.15rem;

  --font-family: 'Poppins', sans-serif;

  --main-viewport-width: 1280px;
  --header-height: calc(3rem + 15px);

  --border-1: 1px solid --color-yellow;
  --border-2: 1px solid transparent;
  --border-3: 2.60188px solid --color-secondary;
  --border-image-slice: 1;
  --toastify-color-progress-success: #0EDA95;
}
